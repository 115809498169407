import useLocalFetch from '../useLocalFetch';
import { FILE_TYPE, POST } from '@dometech-group/dometech-packages';

function useUpload({ url, callback }) {
	const [load, loading] = useLocalFetch({
		handleResult: result => {
			if (result.success) {
				if (callback) callback(result);
			}
		},
	});

	const handleSubmit = body => {
		load({
			url,
			method: POST,
			contentType: FILE_TYPE,
			body,
		});
	};

	return { loading, handleSubmit };
}

export default useUpload;
