import React, { useEffect, useState } from 'react';
import { Form, Button, Segment, Icon, Message } from 'semantic-ui-react';
import JournalSelector from '../fields/JournalSelector';
import EntryDetailCollectionForm from './EntryDetailCollectionForm';
import useCreate from '../hooks/fetch/actions/useCreate';
import { deformatDate } from '@dometech-group/dometech-packages';
import AddComment from '../fields/AddComment';
import AttachmentField from '../fields/AttachmentField';
import { TextAreaAutoRows } from '@dometech-group/dometech-packages-ui-semantic';
import useAttachment from '../hooks/form/useAttachment';

const defaultEntry = { entryDetails: [] };
const initEntry = entry => {
	// cannot set account by id here because labels can be defined from details
	return {
		...entry,
		journal: entry.journal.id,
		entryDate: deformatDate(entry.entryDate),
	};
};

const EntryForm = ({ onSuccess, onCancel, initialEntry, loading: loadingBis }) => {
	const [entry, setEntry] = useState(() =>
		initialEntry ? initEntry(initialEntry) : defaultEntry
	);

	const {
		preSuccess,
		attachment,
		handleAttachment,
		loading: loadingAttachment,
	} = useAttachment({
		entityName: 'entry',
		entity: entry,
		onSuccess,
	});

	const { handleSubmit, loading, error, setError } = useCreate({
		name: 'entry',
		callback: preSuccess,
	});

	// clear errors when entryDetails is updated
	useEffect(() => {
		if (error.entryUnbalanced) {
			if (
				entry.entryDetails.reduce(
					(sum, detail) => sum + detail.credit - detail.debit,
					0
				) == 0
			) {
				setError(prev => ({ ...prev, entryUnbalanced: undefined }));
			}
		}
		if (error.entryDetails) {
			if (entry.entryDetails.length > 0) {
				setError(prev => ({ ...prev, entryDetails: undefined }));
			}
		}
	}, [entry.entryDetails]);

	const handleChange = (e, { name, value }) => {
		setEntry(prev => ({ ...prev, [name]: value }));
	};

	const handleJournal = (e, { value }) => {
		setEntry(prev => ({ ...prev, journal: value }));
	};

	const handleAddEntryDetail = () => {
		setEntry(prev => ({
			...prev,
			entryDetails: [...prev.entryDetails, {}, {}, {}],
		}));
	};

	const handleRemoveEntryDetail = index => {
		const entryDetails = entry.entryDetails.filter((_, i) => i !== index);
		setEntry({ ...entry, entryDetails });
	};

	const handleEntryDetailChange = (index, name, value) => {
		setEntry(prev => {
			const entryDetails = prev.entryDetails.slice();
			const detail = entryDetails[index];
			entryDetails[index] = {
				...detail,
				[name]: typeof value === 'function' ? value(detail) : value,
			};
			return { ...prev, entryDetails };
		});
	};

	const handleError = (name, err) => setError(prev => ({ ...prev, [name]: err }));

	const onSubmit = e => {
		e.preventDefault();
		e.stopPropagation();
		if (loading) return;

		// set entryDetails's account as id, if it has one
		const body = {
			...entry,
			entryDetails: entry.entryDetails.map(detail => ({
				...detail,
				account: detail.account.id ? detail.account.id : detail.account,
			})),
		};
		handleSubmit(body);
	};

	return (
		<Form
			onSubmit={onSubmit}
			error={!!error}
			loading={loading || loadingBis || loadingAttachment}
			className="sub-grabble"
		>
			<Form.Group widths="equal" className="sub-grabble">
				<JournalSelector
					value={entry.journal || ''}
					onChange={handleJournal}
					error={error.journal}
					required
				/>
				<Form.Input
					type="date"
					name="entryDate"
					label="Date"
					value={entry.entryDate || ''}
					onChange={handleChange}
					error={error.entryDate}
					required
				/>
				<Form.Input
					name="reference"
					label="Référence"
					value={entry.reference || ''}
					onChange={handleChange}
					error={error.reference}
				/>
			</Form.Group>
			<TextAreaAutoRows
				id={`entry-${entry.id || ''}-label`}
				name="label"
				label="Libellé d'écriture"
				value={entry.label || ''}
				onChange={handleChange}
				error={error.label}
				required
			/>
			<AddComment
				id={`entry-${entry.id || ''}-comment`}
				onChange={handleChange}
				value={entry.comment}
				error={error.comment}
			/>
			<AttachmentField
				error={error.attachment}
				attachment={attachment}
				handleError={handleError}
				handleChange={handleAttachment}
			/>
			<Segment className="sub-grabble">
				<h3 className="sub-grabble">Lignes de l'écriture</h3>
				<div className="mb-1">
					<EntryDetailCollectionForm
						details={entry.entryDetails}
						handleChange={handleEntryDetailChange}
						handleRemove={handleRemoveEntryDetail}
						error={error.entryDetails}
						className="m-0"
					/>
				</div>
				<Message error content={error.entryUnbalanced} />
				{typeof error.entryDetails === 'string' && (
					<Message error content={error.entryDetails} />
				)}
				<div className="pr-0_5 pl-0_5 sub-grabble">
					<Button type="button" primary basic onClick={handleAddEntryDetail}>
						<Icon name="plus" /> Ajouter 3 lignes
					</Button>
				</div>
			</Segment>
			<div className="flex flex-wrap justify-content-end sub-grabble">
				{onCancel ? (
					<Button type="button" loading={loading} onClick={onCancel}>
						Annuler
					</Button>
				) : null}
				<Button primary type="submit" loading={loading}>
					Valider
				</Button>
			</div>
		</Form>
	);
};

export default EntryForm;
